/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/sceditor@3.2.0/minified/sceditor.min.js
 * - /npm/sceditor@3.2.0/minified/plugins/autoyoutube.js
 * - /npm/sceditor@3.2.0/minified/plugins/undo.js
 * - /npm/sceditor@3.2.0/minified/formats/bbcode.js
 * - /npm/sceditor@3.2.0/minified/plugins/dragdrop.js
 * - /npm/whatwg-fetch@3.6.20/dist/fetch.umd.min.js
 * - /npm/es6-promise@4.2.8/dist/es6-promise.auto.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
